import React from "react";
import styles from "./index.module.css";
import Link from "next/link";
import Layout from "../library/layout";
import BackgroundImage from "../library/backgroundImage";
import CustomImage from "../library/customImage";
import { formatText, renderSublists } from "../library/formatUtils";
import { Content } from "../library/types";
import path from "path";
import fs from "fs";

interface HomeContent {
  section: {
    KSeF_Link_Promotion: Content[];
    KSeF_Link_Promotion_part_2: Content[];
    Documentation_Description: Content[];
  };
}

interface HomeProps {
  content: HomeContent;
}

export const getStaticProps = async () => {
  const filePath = path.join(
    process.cwd(),
    "content_json_files",
    "home_content.json"
  );
  const jsonData: HomeContent = JSON.parse(fs.readFileSync(filePath, "utf8"));

  return {
    props: {
      content: jsonData,
    },
  };
};

export default function Home({ content }: HomeProps) {
  return (
    <Layout
      title="KSeF-link - Strona Główna"
      description="KSeF-link: Zautomatyzuj swoje fakturowanie zgodnie z polskim systemem e-faktur."
    >
      <div className={styles.sectionContainer}>
        <section className={styles.section}>
          <div className={styles.sectionDivider}>
            <div>
              <h2>{"Innowacyjne Narzędzie dla ekspertów rachunkowości"}</h2>
              <p>
                {content.section.KSeF_Link_Promotion.map((part, index) => (
                  <React.Fragment key={index}>
                    {formatText(
                      part.text,
                      part.formats ?? [],
                      part.url,
                      part.color
                    )}
                    {part.sublists && renderSublists(part.sublists, 1)}
                  </React.Fragment>
                ))}
              </p>
            </div>
          </div>
        </section>
      </div>

      <div className={styles.sectionContainer}>
        <section className={styles.section}>
          <div className={styles.sectionDivider}>
            <div>
              <p>
                {content.section.KSeF_Link_Promotion_part_2.map(
                  (part, index) => (
                    <React.Fragment key={index}>
                      {formatText(
                        part.text,
                        part.formats ?? [],
                        part.url,
                        part.color
                      )}
                      {part.sublists && renderSublists(part.sublists, 1)}
                    </React.Fragment>
                  )
                )}
              </p>
            </div>
            <div className={styles.imageWrapper}>
              <CustomImage
                imageSrc="/images/icons/hand_handshake_72.png"
                className={styles.changeColours}
              />
            </div>
          </div>
        </section>
      </div>

      <hr className={styles.sectionSeparator} />

      <BackgroundImage imagePath="/images/background_images/background_image_1.webp" />

      <hr className={styles.sectionSeparator} />

      <div className={styles.sectionContainer}>
        <section className={styles.section}>
          <div className={styles.sectionDivider}>
            <div>
              <h2>{"Pełny widok programu"}</h2>
              <p>
                {content.section.Documentation_Description.map(
                  (part, index) => (
                    <React.Fragment key={index}>
                      {formatText(
                        part.text,
                        part.formats ?? [],
                        part.url,
                        part.color
                      )}
                      {part.sublists && renderSublists(part.sublists, 1)}
                    </React.Fragment>
                  )
                )}
              </p>
            </div>
            <div className={styles.imageWrapper}>
              <CustomImage
                imageSrc="/images/icons/allow_all_72.png"
                className={styles.changeColours}
              />
            </div>
          </div>
        </section>
      </div>

      <hr className={styles.sectionSeparator} />

      <div className={styles.grid}>
        <Link href="/documentation" className={styles.card}>
          <h2>
            Dokumentacja <span>-&gt;</span>
          </h2>
          <p>Opis dostępnych funkcji programu KSeF-Link</p>
        </Link>

        <Link href="/faq" className={styles.card}>
          <h2>
            FAQ <span>-&gt;</span>
          </h2>
          <p>Najczęściej zadawane pytania</p>
        </Link>

        <a href="https://www.podatki.gov.pl/ksef/" className={styles.card}>
          <h2>
            KSeF <span>-&gt;</span>
          </h2>
          <p>Strona Krajowego Systemu e-Faktur na Portalu Podatkowym</p>
        </a>

        <a href="https://tgsoft.pl" className={styles.card}>
          <h2>
            TGSoft <span>-&gt;</span>
          </h2>
          <p>Strona główna firmy TGSoft</p>
        </a>
      </div>
    </Layout>
  );
}
