import React, { useEffect, useState } from "react";
import Image from "next/image";

interface CustomImageProps {
  imageSrc: string;
  alt?: string;
  className?: string;
  width?: number;
  height?: number;
}

const CustomImage: React.FC<CustomImageProps> = ({
  imageSrc,
  alt,
  className,
  width = 100,
  height = 100,
}) => {
  const defaultAlt =
    imageSrc.split("/").pop()?.split(".")[0]?.replace(/-/g, " ") || "Image";

  const [imageSize, setImageSize] = useState({ width, height });

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 700) {
        setImageSize({ width: 50, height: 50 });
      } else if (window.innerWidth < 1200) {
        setImageSize({ width: 60, height: 60 });
      } else {
        setImageSize({ width, height });
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [width, height]);

  return (
    <Image
      src={imageSrc}
      alt={alt || defaultAlt}
      className={className}
      width={imageSize.width}
      height={imageSize.height}
    />
  );
};

export default CustomImage;
