import React from "react";
import styles from "../pages/index.module.css";

type BackgroundImageProps = {
  imagePath: string;
};

const BackgroundImage: React.FC<BackgroundImageProps> = ({ imagePath }) => {
  const inlineStyle = {
    backgroundImage: `url(${imagePath})`,
  };

  const defaultAlt = imagePath.split('/').pop()?.split('.')[0]?.replace(/-/g, ' ') || "Background Image";

  return (
    <div
      className={styles.backgroundImageWrapper}
      style={inlineStyle}
      aria-label={defaultAlt}
      role="img"
    />
  );
};

export default BackgroundImage;
